<template>
	<main
		class="landing"
	>
		<template
			v-for="(page, index) in pages"
		>
			<component
				:is="PAGE_COMPONENTS[page.meta.type]"
				v-if="PAGE_COMPONENTS[page.meta.type] && page"
				:key="`${index}-subpage`"
				v-is-on-screen="{ handler: onScreenHandler, data: { slug: page.meta.slug } }"
				:page-data="page"
				:current="currentSubpage === page.meta.slug"

				@mounted="subpageMountedHandler(page.meta.slug)"
			/>
		</template>
	</main>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { PAGE_TYPES, COMPONENTS_BY_TYPES } from '@/constants/page-types';

function importPageComponent(componentName) {
	return import(/* webpackChunkName: "[request]" */ `@/views/${componentName}`);
}

const PAGE_COMPONENTS = Object.keys(COMPONENTS_BY_TYPES).reduce((imports, type) => ({
	...imports,
	[type]: () => importPageComponent(COMPONENTS_BY_TYPES[type])
}), {});


export default {
	name: 'PageIndex',


	data() {
		return {
			PAGE_COMPONENTS,
			currentSubpage: null,
			loadedPages: [],
		};
	},


	components: {
		...PAGE_COMPONENTS,
	},


	computed: {
		...mapState([ 'route' ]),
		...mapGetters('browser', ['mobileMode']),
		...mapState('browser', { browserFamily: 'family' }),
		...mapState('pages', { allPages: 'list' }),

		pages() {
			if (!this.allPages) return null;
			return this.allPages.filter(page => page.on_landing || page.meta.type === PAGE_TYPES.HOME);
		}
	},


	methods: {
		onScreenHandler(data) {
			this.currentSubpage = data.slug;

			let willReplace = this.$router.replace({
				name: data.slug,
				query: this.$route.query,
				params: {
					routeByScroll: true,
					lang: this.route.params.lang,
				},
			});

			if (willReplace && willReplace instanceof Promise) willReplace.catch(err => {
				// console.debug(`Route update message: ${err.message}`)
			});
		},

		subpageMountedHandler(slug) {
			if (this.loadedPages.includes(slug)) return null;
			this.loadedPages.push(slug);
			if (this.loadedPages.length < this.pages.length) return null;

			const scrollFix = { x: 0, y: 0 };
			const headerPanelEl = document.querySelector('#app > .header-panel');
			if (headerPanelEl) scrollFix.y += -headerPanelEl.clientHeight;
			const el = document.getElementById(this.route.name);

			if (!el) return null;
			window.scrollTo({
				left: el.offsetLeft + scrollFix.x,
				top: el.offsetTop + scrollFix.y,
				behavior: 'instant',
			});

		}
	},


	beforeRouteLeave(to, from, next) {
		if (to.params.routeByScroll) { return next(); }

		// fix scrollTo for safari
		const fix = { x: 0, y: 0 };

		const headerPanelEl = document.querySelector('#app > .header-panel');
		if (headerPanelEl) fix.y += -headerPanelEl.clientHeight;

		const el = document.getElementById(to.name);
		if (!el) { return next(); }

		window.scrollTo({
			left: el.offsetLeft + fix.x,
			top: el.offsetTop + fix.y,
			behavior: 'smooth',
		});
	},

};
</script>

<style lang="sass" scoped>
.landing
	display: flex
	flex-direction: column
	align-items: stretch
	width: 100%
	flex-wrap: nowrap

.subpage
	flex-shrink: 0
</style>
